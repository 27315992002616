.orderSuccess {
  margin: auto;
  text-align: center;
  padding: 10vmax;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.orderSuccess > svg {
  font-size: 7vmax;
  color: #f3d2c1;
}
.orderSuccess > p {
  font-size: 2vmax;
}
.orderSuccess > a {
  background-color: black;
  color: white;
  border: none;
  padding: 1vmax 3vmax;
  cursor: pointer;
  font: 400 1vmax "Quicksand";
  text-decoration: none;
  margin: 2vmax;
  border-radius: 10px;
  transition: all 0.3s ease;
}
.orderSuccess > a:hover {
  background: #f3d2c1;
  color: black;
}

@media screen and (max-width: 600px) {
  .orderSuccess > a {
    padding: 3vw 6vw;
    font: 400 4vw "Quicksand";
    margin: 2vmax;
  }

  .orderSuccess > svg {
    font-size: 20vw;
  }
  .orderSuccess > p {
    margin: 2vmax;
    font-size: 5vw;
  }
}
