.category {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  overflow: hidden;
  padding: 5rem 0;
  flex-direction: column;
}
.heading {
  font-size: 6rem;
  width: 70%;
  text-align: center;
  font-weight: 100;
  position: relative;
}
.heading img {
  height: 8rem;
  position: absolute;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.heading img:hover {
  transform: rotate(360deg);
}
.para {
  text-align: center;
  margin: 3rem 0;
  width: 50%;
}

.cate-section {
  width: 80vw;
  height: auto;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 4rem;
  margin-bottom: 4rem;
  margin-top: 2vmax;
  justify-content: center;
}
.first-cate {
  flex-basis: 50%;
  flex-wrap: wrap;
  width: 100%;
  height: 60vh;
  position: relative;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  cursor: pointer;
}

.first-cate img {
  border-radius: 20px;
  width: 100%;
  height: 60vh;
  object-fit: cover;
}

.first-cate button {
  position: absolute;
  top: 84%;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  outline: none;
  border: none;
  background-color: black;
  color: white;
  font-size: 1.3rem;
  transition: all 0.3s ease-in-out;
  font-weight: bold;
  padding: 0.8vmax 2vmax;
  border-radius: 10px;
}

.first-cate button:hover {
  color: black;
  background-color: white;
}

@media (max-width: 1024px) {
  .heading {
    font-size: 4rem;
    width: 80%;
  }
  .heading img {
    height: 6rem;
  }
  .cate-section {
    gap: 2rem;
  }
  .first-cate {
    height: 30vh;
  }
  .sec {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .sec .first-cate {
    height: 30vh;
    flex-basis: 48%;
  }
}
@media (max-width: 768px) {
  .cate-section {
    grid-template-columns: auto auto;
  }
  .first-cate img {
    height: 100%;
  }
}
@media (max-width: 568px) {
  .heading {
    font-size: 2.5rem;
  }
  .heading img {
    height: 4rem;
  }
  .para {
    width: 70%;
  }
  .cate-section {
    flex-direction: column;
    margin-bottom: 2rem;
  }
  .sec .first-cate {
    margin: auto;
    width: 70vw;
    flex-basis: 100%;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
  }
  .first-cate img {
    height: 80%;
  }
  .sec .first-cate:nth-child(1) {
    margin-bottom: 0;
  }
  .first-cate button {
    top: 85%;
    font-size: 1rem;
  }
  .first-cate {
    box-shadow: none;
  }
}
