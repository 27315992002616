.collectionHeading {
  display: flex;
  text-align: center;
  font-weight: bold;
  justify-content: center;
  font-size: 3vmax;
  margin: 2vmax 0 3vmax 0;
}
.collectBtn {
  border: none;
  padding: 0.8vmax 2vmax;
  margin: 2vmax;
  background: black;
  color: white;
  border-radius: 20px;
  cursor: pointer;
}
.collectionDiv {
  display: grid;
  justify-content: center;
}
.cc {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
}
.aboutCollection {
  margin: 20px;
}
.collectionImage {
  width: 100%;
  height: auto;
  border-radius: 20px;
}
.collectionImage img {
  height: 65vh;
  width: 47vw;
  object-fit: cover;
  border-radius: 20px;
}

@media (max-width: 1200px) {
  .cc {
    display: flex;
    flex-direction: column;
  }
  .collectionImage img {
    height: 40vh;
    width: 100%;
    object-fit: cover;
  }
}
