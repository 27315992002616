footer {
  width: 100%;
  position: relative;
  bottom: 0;
  /* background: linear-gradient(to right, #fcdddd, #dfab99); */
  background: #f3d2c1;
  /* color: #001858; */
  padding: 50px 0 30px;
  /* border-top-left-radius: 125px;
  border-top-right-radius: 125px; */
  font-size: 15px;
  line-height: 20px;
}
.row {
  width: 90%;
  margin: auto;
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.row img {
  height: 10rem;
}

.col {
  flex-basis: 20%;
  padding: 10px;
}
.col:nth-child(2),
.col:nth-child(3) {
  flex-basis: 15%;
}
.col h2 {
  width: fit-content;
  margin-bottom: 20%;
  position: relative;
}
.email_id {
  width: fit-content;
  border-bottom: 1px solid black;
  margin: 20px 0;
}

.col ul li {
  list-style: none;
  margin-bottom: 12px;
}

.col ul li a {
  text-decoration: none;
}

.footer_content {
  width: 80%;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: auto;
  flex-wrap: wrap;
  margin-top: 20px;
}
.footer_content h2 {
  margin-bottom: 20px;
}
.footer_content span {
  margin-top: 20px;
}

.icon {
  margin-top: 20px;
}
.icon a {
  padding-left: 20px;
}

form {
  margin-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid black;
  margin-bottom: 50px;
}
form input {
  width: 100%;
  background: transparent;
  border: 0;
  outline: none;
}
form button {
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
hr {
  width: 90%;
  border: 0;
  border-bottom: 1px solid black;
  margin: 20px auto;
}

.underline {
  width: 100%;
  height: 2px;
  background: #ffffff;
  border-radius: 3px;
  position: relative;
  top: 25%;
  left: 0;
  overflow: hidden;
}
.underline span {
  width: 15px;
  height: 100%;
  background: black;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 10%;
  animation: moving 2s linear infinite;
}

@keyframes moving {
  0% {
    left: -20px;
  }
  100% {
    left: 100%;
  }
}

@media (max-width: 1024px) {
  footer {
    bottom: unset;
    width: auto;
  }
  .row {
    width: 90%;
  }
  .col {
    flex-basis: 40%;
  }
  .col:nth-child(3) {
    flex-basis: 30%;
  }
  .col:nth-child(2) {
    flex-basis: 20%;
  }
}
@media (max-width: 568px) {
  footer {
    bottom: unset;
    width: auto;
  }
  .row {
    width: 80%;
  }
  .col ul li a {
    font-size: 2vmax;
  }
  .col {
    flex-basis: 100%;
  }
  .col:nth-child(2),
  .col:nth-child(3) {
    flex-basis: 100%;
  }
}
