.paymentContainer {
  width: 100vw;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.paymentBox {
  height: auto;
  box-sizing: border-box;
  overflow: hidden;
  width: 50vw;
}

.paymentForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2vmax;
  justify-content: space-evenly;
  height: 80%;
  transition: all 0.5s;
  border: none;
}

.paymentForm > div {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 1vmax 0;
}
.paymentForm > div > input {
  background-color: black;
  color: white;
  padding: 0.5vmax;
  border-radius: 20px;
  margin: auto;
  cursor: pointer;
}

@media (max-width: 568px) {
  .paymentBox {
    width: 90vw;
  }
}
