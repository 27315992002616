.productsSec {
  overflow: hidden;
  position: relative;
  flex: 1;
  transition: all 0.3s ease-in-out;
}
.productsHeading {
  margin: 2vmax auto;
  text-align: center;
}
.productPageImg {
  width: 100vw;
  height: 90vh;
  object-fit: cover;
  overflow: hidden;
}

.products {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0 2vmax;
  justify-content: space-between;
  min-height: 30vh;
}

.paginationBox {
  display: flex;
  justify-content: center;
  margin: 6vmax;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 0;
}

.page-item {
  background-color: rgb(255, 255, 255);
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.178);
  padding: 1vmax 1.5vmax;
  transition: all 0.3s;
  cursor: pointer;
}
.page-item:first-child {
  border-radius: 5px 0 0 5px;
}

.page-item:last-child {
  border-radius: 0 5px 5px 0;
}
.page-link {
  text-decoration: none;
  font: 300 0.7vmax "Roboto";
  color: rgb(80, 80, 80);
  transition: all 0.3s;
}

.page-item:hover {
  background-color: rgb(230, 230, 230);
}

.page-item:hover .page-link {
  color: rgb(0, 0, 0);
}

.pageItemActive {
  background-color: black;
}

.pageLinkActive {
  color: white;
}
.filterHeading {
  align-items: center;
  text-align: center;
}
.filterBox {
  width: 100vw;
  padding: 2rem;
  display: none;
  /* position: absolute; */
  /* top: 55vmax;
  left: 4vmax; */
  /* display: flex; */
}
.filterBox > div {
  flex-basis: 33.3%;
  margin-right: 3rem;
}
.filterBox > div {
  width: 20vw;
}

.categoryBox {
  padding: 1vmax 5vmax;
  display: flex;
  justify-content: flex-end;
  text-align: center;
}

.category-link {
  list-style: none;
  color: rgba(0, 0, 0, 0.61);
  font: 400 0.8vmax "Roboto";
  margin: 0.4vmax;
  cursor: pointer;
  transition: all 0.5s;
}
.category-link:hover {
  color: tomato;
}

.filterBox > fieldset {
  border: none;
}
@media screen and (max-width: 1020px) {
  .products {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .productPageImg {
    height: 40vh;
  }
}

@media screen and (max-width: 568px) {
  .productPageImg {
    height: 30vh;
  }
  .productsHeading {
    font-size: 2vmax;
  }
  .products {
    grid-template-columns: 1fr 1fr;
    padding: 0 1vmax;
  }
  .filterBox {
    width: 100%;
    margin: auto;
    /* display: flex; */
  }
  .page-link {
    font: 300 1.7vmax "Roboto";
  }
  .category-link {
    font: 400 1.8vmax "Roboto";
  }
  .categoryBox {
    justify-content: center;
  }
}
