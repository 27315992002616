.CartItemCard {
  display: flex;
  padding: 1vmax;
  height: 12vh;
  align-items: center;
  box-sizing: border-box;
}
.CartItemCard > img {
  width: 6vmax;
  border-radius: 5px;
  object-fit: cover;
}
.CartItemCard > div {
  display: flex;
  flex-direction: column;
  margin: 0.3vmax 1vmax;
  padding: 1vmax;
}
.CartItemCard > div > a {
  text-decoration: none;
  font-weight: 300;
}
.CartItemCard > div > span {
  text-decoration: none;
  font-weight: 300;
  font-size: 1.1vmax;
}
.CartItemCard > div > p {
  cursor: pointer;
  font-weight: 900;
  color: red;
}

@media screen and (max-width: 568px) {
  .CartItemCard {
    padding: 2vmax 0;
    align-items: flex-start;
    height: auto;
    width: auto;
    /* background-color: aqua; */
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
  .CartItemCard > img {
    width: 15vmax;
  }

  .CartItemCard > div {
    margin: 1vmax;
  }

  .CartItemCard > div > a {
    font: 900 2vmax "Quicksand";
  }

  .CartItemCard > div > span {
    font: 300 1.9vmax "Quicksand";
  }

  .CartItemCard > div > p {
    font: 100 1.8vmax "Quicksand";
  }
}
