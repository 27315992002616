.resetPasswordContainer {
  width: 100vw;
  height: 80vh;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0%;
  left: 0;
  border-radius: 20px;
  /* z-index: 6000; */
}

.resetPasswordBox {
  border-radius: 20px;
  width: 25vw;
  height: 60vh;
  box-sizing: border-box;
  overflow: hidden;
}
.resetPasswordBox > h2 {
  text-align: center;
  padding-top: 1.5vmax;
  margin: auto;
  width: 70%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
}

.resetPasswordForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2vmax;
  justify-content: space-evenly;
  height: 80%;
  transition: all 0.5s;
  border: none;
}

.resetPasswordForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}

.resetPasswordForm > div > input {
  padding: 1vmax 4vmax;
  padding-right: 1vmax;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.267);
  border-radius: 4px;
  font: 300 0.9vmax normal;
  outline: none;
}

.resetPasswordForm > div > svg {
  position: absolute;
  transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

.resetPasswordBtn {
  border: none;
  background-color: #f3d2c1;
  color: black;
  font: 300 0.9vmax;
  width: 100%;
  padding: 0.8vmax;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
}

.resetPasswordBtn:hover {
  background-color: black;
  color: white;
}
@media screen and (max-width: 1024px) {
  .resetPasswordBox {
    width: 45vw;
  }
}

@media screen and (max-width: 600px) {
  .resetPasswordContainer {
    height: 70vh;
  }
  .resetPasswordBox {
    width: 90vw;
    height: 50vh;
  }

  .resetPasswordForm {
    padding: 5vmax;
  }

  .resetPasswordForm > div > input {
    padding: 5vw 10vw;
    font: 300 4vw normal;
  }
  .resetPasswordForm > div > svg {
    font-size: 2.8vmax;
  }

  .resetPasswordBtn {
    font: 300 1.9vmax "Roboto";
    padding: 1.8vmax;
  }
}
