/* blogs */
.blogger {
  margin: 1vmax;
}
.blogger .headingBlog {
  display: flex;
  text-align: center;
  font-weight: bold;
  justify-content: center;
  margin: 2vmax 0 3vmax 0;
  text-decoration: underline;
}

.blogList-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3vmax;
}

@media (max-width: 768px) {
  .blogList-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .blogList-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}
.blogItem-wrap {
  display: flex;
  flex-direction: column;
}

.blogItem-cover {
  width: 100%;
  height: 22vmax;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 0.5rem;
}
.blogItem-wrap h3 {
  margin: 0.5rem 0 1rem 0;
  flex: 1;
}

.blogItem-desc {
  position: relative;
  max-height: 55px;
  overflow: hidden;
  padding-right: 0.6rem;
  font-size: 1vmax;
}

.blogItem-desc::before {
  position: absolute;
  content: "...";
  bottom: 0;
  right: 0;
}

.footerblog {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  justify-content: space-between;
}

.blogItem-link {
  text-decoration: none;
  color: inherit;
}

.blogItem-author {
  display: flex;
  align-items: center;
}

.blogItem-author p {
  font-size: 0.8vmax;
  color: #a9a9a9;
  font-weight: 600;
}

/* blog page */

.blog-wrap {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.blog-goBack {
  text-decoration: none;
  font-size: 1.5vmax;
  color: #a9a9a9;
  font-weight: 500;
  margin: 2rem 0 1rem 0;
  display: block;
}
.blogHead {
  text-align: center;
}

.blog-date {
  font-size: 1.2vmax;
  color: #a9a9a9;
  font-weight: 500;
}

.blog-wrap img {
  /* width: 100%; */
  height: 50vmax;
}

.blog-desc {
  padding: 1rem;
  margin-top: 1.5rem;
}

@media (max-width: 768px) {
  .blog-wrap {
    grid-template-columns: 1fr;
  }
  .blog-wrap img {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 600px) {
  .blog-wrap {
    grid-template-columns: 1fr;
  }
  .blog-wrap img {
    width: 100%;
    height: auto;
  }
}
