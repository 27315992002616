.slider-container {
  height: 85vh;
  width: 100%;
  position: relative;
  margin: auto;
  overflow: hidden;
  transition: all ease;
}

.active {
  display: inline-block;
}
.inactive {
  display: none;
}

.slides {
  height: 85vh;
  width: 100%;
  position: relative;
}
.slide-image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  /* transition: ease-in-out; */
}
.slide-title,
.slide-text {
  width: 25%;
  height: 25%;
  position: absolute;
  color: white;
  font-size: 50px;
  text-align: center;
  top: 5%;
  left: 72%;
  z-index: 10;
  /* background-color: rgba(0, 0, 0, 0.5); */
  font-family: Oleo Script Swash Caps;
  border-radius: 20px 20px 0px 0;
}
.slide-text {
  border-radius: 0px 0 20px 20px;
  height: 15%;
  top: 30%;
  font-size: 2rem;
}
.button {
  padding: 5px 10px;
  position: absolute;
  color: black;
  font-size: 15px;
  text-align: center;
  top: 65%;
  left: 81%;
  z-index: 10;
  margin-top: 10px;
  background-color: #f3d2c1;
  /* font-family: Oleo Script Swash Caps; */
  border-radius: 10px;
  cursor: pointer;
}

.prev,
.next {
  cursor: pointer;
  z-index: 100;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 1rem;
  margin-top: -3rem;
  font-size: 30px;
  font-weight: bold;
  border-radius: 0px 5px 5px 0px;
  color: white;
}
.prev:hover,
.next:hover {
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.5s ease-in;
}
.next {
  right: 0;
  border-radius: 5px 0px 0px 5px;
}

.all-dots {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 90%;
  justify-content: center;
  z-index: 200;
}
.dot {
  cursor: pointer;
  height: 1.5rem;
  width: 1.5rem;
  margin: 0px 3px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  display: inline-block;
}
.active-dot,
.dot:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
@media (max-width: 1024px) {
  .slider-container {
    height: 40vh;
  }
  .slides {
    height: 40vh;
  }
  .slide-image {
    height: 40vh;
  }
  .slide-title,
  .slide-text {
    width: 60%;
    height: 25%;
    position: absolute;
    color: white;
    font-size: 30px;
    text-align: center;
    top: 10%;
    left: 20%;
    z-index: 10;
    /* background-color: rgba(0, 0, 0, 0.4); */
    font-family: Oleo Script Swash Caps;
    border-radius: 20px;
  }
  .slide-text {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0px 0 20px 20px;
    height: 15%;
    top: 20%;
    font-size: 1rem;
  }
  .button {
    padding: 5px 10px;
    position: absolute;
    color: black;
    font-size: 10px;
    text-align: center;
    top: 25%;
    left: 45%;
    z-index: 10;
    margin-top: 10px;
    background-color: #f3d2c1;
    /* font-family: Oleo Script Swash Caps; */
    border-radius: 10px;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .slider-container {
    height: 40vh;
  }
  .slides {
    height: 40vh;
  }
  .slide-image {
    height: 40vh;
  }
}
@media (max-width: 576px) {
  .slider-container {
    height: 35vh;
  }
  .slides {
    height: 35vh;
  }
  .slide-image {
    height: 35vh;
  }
}

.fade {
  animation-name: fade;
  animation-duration: 1s;
  transition: all ease-in-out;
}

@keyframes fade {
  from {
    transform: translateX(10%);
  }
  to {
    transform: translateX(0%);
  }
}
