.shippingContainer {
  width: 100vw;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.shippingBox {
  width: 60vw;
  box-sizing: border-box;
  overflow: hidden;
}

.shippingHeading {
  text-align: center;
  color: rgba(0, 0, 0, 0.664);
  font: 400 1.3vmax "Quicksand";
  padding: 1.3vmax;
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
  width: 50%;
  margin: auto;
}

.shippingForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2vmax;
  justify-content: space-evenly;
  height: 80%;
  transition: all 0.5s;
  border: none;
  gap: 1.5rem;
}

.shippingForm > div {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1.5rem;
}

.shippingForm > div > input,
.shippingForm > div > select {
  padding: 1vmax 4vmax;
  width: 100%;
  border: 0;
  background: transparent;
  border-radius: 10px;
  font: 300 0.9vmax normal;
  outline: none;
  border-bottom: 1px solid black;
}

.shippingForm > div > svg {
  position: absolute;
  transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

.shippingBtn {
  border: none;
  background-color: black;
  border-radius: 20px;
  color: white;
  font: 300 1vmax "Quicksand";
  width: 100%;
  padding: 1vmax;
  cursor: pointer;
  transition: all 0.5s;
  outline: none;
  margin: 2vmax;
}

.shippingBtn:hover {
  background-color: #f3d2c1;
  color: black;
}

@media (max-width: 1024px) {
  .shippingBox {
    width: 80vw;
  }
}

@media screen and (max-width: 768px) {
  .shippingBox {
    width: 60vw;
  }

  .shippingHeading {
    font-size: 1.5rem;
    width: 80%;
  }

  .shippingForm {
    padding: 2rem;
    height: auto;
  }

  .shippingForm > div > input,
  .shippingForm > div > select {
    padding: 0.8rem 3rem;
    font-size: 0.9rem;
  }

  .shippingForm > div > svg {
    font-size: 1.3rem;
    transform: translateX(0.8rem);
  }

  .shippingBtn {
    font-size: 1rem;
    margin: 1.5rem 0;
  }
}

@media screen and (max-width: 568px) {
  .shippingBox {
    width: 80%;
    height: auto;
  }

  .shippingHeading {
    font-size: 1.3rem;
    width: 90%;
  }

  .shippingForm {
    padding: 1.5rem;
    height: auto;
    margin-bottom: 1rem;
  }

  .shippingForm > div > input,
  .shippingForm > div > select {
    padding: 0.5rem 2rem;
    font-size: 0.8rem;
  }

  .shippingForm > div > svg {
    font-size: 1rem;
    transform: translateX(0.6rem);
  }

  .shippingBtn {
    font-size: 0.9rem;
    margin: 1.2rem 0;
  }
}
