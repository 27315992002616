.profileContainer {
  display: flex;
  height: 70vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  gap: 5vmax;
}
.avatarDetails {
  display: flex;
  flex-direction: column;
}
.avatarDetails img {
  width: 10vmax;
  border-radius: 50%;
  margin: 4vmax 0;
}
.avatarDetails a {
  display: flex;
  justify-content: center;
}
.detailsdiv {
  margin: 12rem 0 0 0;
}
.detailsdiv div {
  margin: 2rem 0;
}

.profilebtn {
  display: flex;
  flex-direction: column;
}

.profilebtn a,
.avatarDetails a {
  margin: 0.5vmax 0;
  background: black;
  color: white;
  font-size: 1vmax;
  padding: 0.3vmax;
  border-radius: 20px;
  text-align: center;
}
@media (max-width: 600px) {
  .profileContainer {
    height: 50vh;
  }
  .profilebtn a,
  .avatarDetails a {
    padding: 1vmax;
    font-size: 1.3vmax;
  }
}
