.updateProfileContainer {
  width: 100vw;
  height: 80vh;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* top: 0%;
  left: 0; */
  border-radius: 20px;
  /* z-index: 6000; */
}

.updateProfileBox {
  border-radius: 20px;
  /* background-color: white; */
  width: 25vw;
  height: 60vh;
  box-sizing: border-box;
  overflow: hidden;
}
.updateProfileBox > h2 {
  text-align: center;
  padding-top: 1.5vmax;
  margin: auto;
  width: 50%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
}

.updateProfileForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2vmax;
  justify-content: space-evenly;
  height: 80%;
  transition: all 0.5s;
  border: none;
}

.updateProfileForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}

.updateProfileForm > div > input {
  padding: 1vmax 4vmax;
  padding: 1vmax 4vmax;
  width: 100%;
  border: 0;
  background: transparent;
  border-radius: 10px;
  font: 300 0.9vmax normal;
  outline: none;
  border-bottom: 1px solid black;
}

.updateProfileForm > div > svg {
  position: absolute;
  transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

#updateProfileImage > img {
  width: 3vmax;
  border-radius: 100%;
  margin: 1vmax;
}
#updateProfileImage > input {
  display: flex;
  padding: 0%;
}

#updateProfileImage > input::file-selector-button {
  cursor: pointer;
  width: 100%;
  z-index: 2;
  height: 3vh;
  border: none;
  margin: 0%;
  font: 400 0.8vmax cursive;
  transition: all 0.5s;
  padding: 0 1vmax;
  color: rgba(0, 0, 0, 0.623);
  background-color: rgb(255, 255, 255);
}

#updateProfileImage > input::file-selector-button:hover {
  background-color: rgb(235, 235, 235);
}

.updateProfileBtn {
  border: none;
  background-color: black;
  color: white;
  font: 300 0.9vmax;
  width: 100%;
  padding: 0.8vmax;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
}

.updateProfileBtn:hover {
  background-color: #f3d2c1;
  color: black;
}

@media screen and (max-width: 1024px) {
  .updateProfileBox {
    width: 45vw;
  }
}

@media screen and (max-width: 600px) {
  /* .LoginSignUpContainer {
      background-color: white;
    } */
  .updateProfileBox {
    width: 90vw;
  }

  .updateProfileForm {
    padding: 5vmax;
  }

  .updateProfileForm > div > input {
    padding: 5vw 10vw;
    font: 300 4vw normal;
  }
  .updateProfileForm > div > svg {
    font-size: 2.8vmax;
  }

  #updateProfileImage > img {
    width: 8vmax;
    border-radius: 100%;
  }

  #updateProfileImage > input::file-selector-button {
    height: 7vh;
    font: 400 1.8vmax cursive;
  }

  .updateProfileBtn {
    font: 300 1.9vmax "Roboto";
    padding: 1.8vmax;
  }
}
