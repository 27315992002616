.backDrop {
  height: 100%;
  width: 100%;
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}
.sDrawer {
  z-index: 1500;
  position: absolute;
  top: 6.5%;
  /* height: 70vh; */
  border-radius: 0 20px 20px 0;
  width: 65%;
  background: #fef6e4;
  transition: all 0.3s ease;
}

.transOne {
  transform: translateX(0);
}
.transTwo {
  transform: translateX(-100%);
}
.dnav_brand img {
  height: 15vh;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.dnav_menu,
.dnicon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding-top: 10px;
  z-index: 2900;
}
.dnicon {
  padding-bottom: 30px;
  gap: 1rem;
  cursor: pointer;
  flex-direction: row;
  /* color: #001858; */
}
.dnicon li:hover {
  background: rgba(248, 245, 245, 0.5);
  border-radius: 50%;
}
