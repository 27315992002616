.loading {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  max-width: 100%;
}
/* .loading > div {
  width: 10vmax;
  height: 10vmax;
  border-bottom: 5px solid rgba(0, 0, 0, 0.719);
  border-radius: 50%;
  animation: loadingRotate 0.8ms linear infinite;
  transition: all ease;
}
@keyframes loadingRotate {
  to {
    transform: rotateZ(-360deg);
  }
} */
