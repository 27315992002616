.aboutus {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 6fr 1fr;
}
.about {
  width: 50vw;
  border-radius: 1rem;
}

@media (max-width: 768px) {
  .aboutus {
    grid-template-columns: 1fr;
  }
  .about {
    width: 100vw;
  }
}
