.slide-modal {
  position: fixed;
  bottom: 10px;
  left: -100%; /* Initially hide the modal to the left */
  transition: left 0.5s;
  z-index: 1000;
  background: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  padding: 5px;
  max-width: 400px;
}

.slide-modal.visible {
  left: 10px; /* Show the modal by sliding it in from the left */
}

.slide-modal .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.slide-modal .close {
  cursor: pointer;
  font-size: 25px;
  font-weight: bold;
  margin-left: 10px;
}

.purchase-info {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 10px;
  border-right: 1px solid #ddd;
}

.user-name {
  font-weight: bold;
}
.ellipsis {
  display: inline-block;
  width: 250px; /* Adjust the maximum width as needed */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowed content */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
}

.product-name {
  margin-top: 5px;
}

.time-ago {
  font-size: 12px;
  color: red;
}

.product-image img {
  width: 70px;
  height: 70px;
  margin-left: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Adjust styles for smaller screens like mobile devices */
@media (max-width: 768px) {
  .slide-modal {
    max-width: 100%; /* Reduce the width on smaller screens */
  }

  .product-image img {
    width: 50px; /* Reduce image size on smaller screens */
    height: 50px;
    margin-left: 10px;
  }

  .ellipsis {
    width: 200px; /* Adjust the maximum width for text */
  }
}
