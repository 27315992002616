.announ {
  height: 6vh;
  width: 100%;
  background: #f3d2c1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.announ span {
  width: 100vw;
  font-weight: bold;
  font-size: 1vmax;
}

.activee {
  display: inline-block;
}
.inactive {
  display: none;
}
@media (max-width: 1024px) {
  .announ span {
    font-size: 1.5vmax;
  }
}
