.confirmOrderPage {
  height: auto;
  display: grid;
  grid-template-columns: 6fr 3fr;
}

.confirmOrderPage > div:last-child {
  border-left: 1px solid rgba(0, 0, 0, 0.247);
}

.confirmshippingArea {
  padding: 3vmax 5vmax;
  padding-bottom: 0%;
}

.confirmshippingArea > p {
  font: 400 1.8vmax "Quicksand";
}

.confirmshippingAreaBox,
.confirmCartItemsContainer {
  margin: 2vmax 0;
}

.confirmshippingAreaBox > div {
  display: flex;
  margin: 1vmax 0;
  align-items: center;
}

.confirmshippingAreaBox > div > p {
  font: 400 1vmax "Quicksand";
  color: black;
}
.confirmshippingAreaBox > div > span {
  margin: 0 1vmax;
  font: 100 1vmax "Quicksand";
  color: #575757;
}

.confirmCartItems > p {
  font: 400 1.8vmax "Quicksand";
}

.confirmCartItems {
  padding: 5vmax;
  padding-top: 2vmax;
}

.confirmCartItemsContainer {
  max-height: 25vmax;
  overflow-y: auto;
}

.confirmCartItemsContainer > div {
  border-bottom: 1px solid black;
  padding: 1vmax;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  font-size: 1.3vmax;
  align-items: center;
  justify-content: space-between;
  margin: 2vmax 0;
}

.confirmCartItemsContainer > div > img {
  width: 8vmax;
  border-radius: 10px;
}

.confirmCartItemsContainer > div > a {
  color: #575757;
  margin: 0 2vmax;
  text-decoration: none;
}

.confirmCartItemsContainer > div > span {
  font: 900 1vmax "Quicksand";
  color: #5e5e5e;
}

.orderSummary {
  padding: 3vmax 7vmax 0 7vmax;
}

.orderSummary > p {
  text-align: center;
  font: 400 1.8vmax "Quicksand";
  border-bottom: 1px solid rgba(0, 0, 0, 0.267);
  padding: 1vmax;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
}

.orderSummary > div > div {
  display: flex;
  font: 300 1vmax "Quicksand";
  justify-content: space-between;
  margin: 2vmax 0;
}
.orderSummary > div > div > span {
  color: rgba(0, 0, 0, 0.692);
}

.orderSummaryTotal {
  display: flex;
  font: 300 1vmax "Quicksand";
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.363);
  padding: 2vmax 0;
}

.orderSummary > button,
.orderSummary > a > button {
  background-color: black;
  border-radius: 20px;
  color: white;
  width: 100%;
  padding: 1vmax;
  border: none;
  margin: auto;
  cursor: pointer;
  transition: 0.5s;
  font: 400 1vmax "Quicksand";
}
.orderSummary > a > button {
  margin-top: 2vmax;
}
.orderSummary > button:hover,
.orderSummary > a > button:hover {
  background-color: #f3d2c1;
  color: black;
}

@media screen and (max-width: 1024px) {
  .orderSummary {
    padding: 3vmax 2vmax 0 2vmax;
  }
  .confirmCartItemsContainer {
    max-height: 85vw;
    width: 100%;
  }
}

@media screen and (max-width: 568px) {
  .confirmOrderPage {
    grid-template-columns: 1fr;
    height: unset;
  }

  .confirmOrderPage > div:last-child {
    border-left: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.247);
  }

  .confirmshippingArea > p {
    font: 400 6vw "Quicksand";
  }
  .confirmshippingAreaBox {
    width: 100%;
  }

  .confirmshippingAreaBox > div {
    display: flex;
    width: 100%;
    margin: 6vw 0;
  }

  .confirmshippingAreaBox > div > p {
    font: 400 4vw "Quicksand";
  }
  .confirmshippingAreaBox > div > span {
    font: 100 4vw "Quicksand";
  }

  .confirmCartItems > p {
    font: 400 6vw "Quicksand";
  }

  .confirmCartItemsContainer {
    max-height: 60vw;
    width: 100%;
  }

  .confirmCartItemsContainer > div {
    font: 400 4vw "Quicksand";
    margin: 4vw 0;
    display: flex;
  }

  .confirmCartItemsContainer > div > img {
    width: 20vw;
  }

  .confirmCartItemsContainer > div > a {
    margin: 0;
    width: 30%;
  }

  .confirmCartItemsContainer > div > span {
    font: 100 4vw "Quicksand";
  }

  .orderSummary {
    padding: 12vw;
  }

  .orderSummary > p {
    font: 400 6vw "Quicksand";
    padding: 4vw;
  }

  .orderSummary > div > div {
    font: 300 4vw "Quicksand";
  }

  .orderSummaryTotal {
    font: 300 4vw "Quicksand";
    padding: 5vw 0;
  }

  .orderSummary > button,
  .orderSummary > a > button {
    padding: 2vw;
    font: 400 4vw "Quicksand";
  }
}
