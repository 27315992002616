/* new design */

.card_wrapper {
  margin: 2vmax 4vmax;
}
.pcard {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
}
.CarouselImage {
  margin-top: 3vmax;
  width: 45vw;
  object-fit: cover;
  border-radius: 20px;
}
.productImage {
  display: flex;
  flex-wrap: wrap;
}
.productImage > img {
  width: 8vw;
  height: 15vh;
  object-fit: cover;
  padding: 0.5rem;
  cursor: pointer;
  z-index: 9;
}

.product_content {
  padding: 0 3vmax 1vmax 3vmax;
}
.product_title {
  font-size: 2.5vmax;
  text-transform: capitalize;
  font-weight: 700;
  position: relative;
  margin: 1vmax 0;
}
.product_title::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 25%;
  background: black;
}
.product_link {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1vmax;
  display: inline-block;
  margin-bottom: 0.5vmax;
  padding: 0.4vmax;
  transition: all 0.5s ease;
  background: #f3d2c1;
  border-radius: 10px;
}
.freqLink > a {
  text-decoration: none;
}
.product_link:hover {
  opacity: 0.9;
}
.product_rating {
  display: flex;
  flex-direction: column;
}

.product_price {
  margin: 1vmax 0;
  font-size: 1vmax;
  font-weight: 700;
}

.dd {
  text-decoration: line-through;
  color: red;
}

.product_detail h2 {
  text-transform: capitalize;
  font-size: 2vmax;
  padding-bottom: 0.6vmax;
}
.product_detail p {
  padding: 0.3vmax;
  opacity: 0.8;
  text-align: justify;
}

.product_detail ul {
  margin: 1vmax 0;
  font-weight: bold;
}
.product_detail ul > li {
  margin: 1vmax 0;
  font-weight: 300;
}
.usageLink {
  align-items: center;
  display: flex;
}
.purchase_info {
  margin: 1.5vmax 0;
  display: flex;
  flex-direction: column;
}
.purchase_info input,
.purchase_info .btn,
.Freqbtn {
  border: 1.5px solid #ddd;
  border-radius: 25px;
  text-align: center;
  padding: 0.7vmax 0.8vmax;
  outline: none;
  margin-right: 0.7vmax;
  margin-bottom: 1vmax;
  outline: none;
}
.purchase_info input {
  width: 4vmax;
}
.purchase_info .btn {
  cursor: pointer;
  color: #fff;
  background-color: black;
  transition: all 0.2s ease;
}
.Freqbtn {
  cursor: pointer;
  color: #fff;
  transition: all 0.2s ease;
  margin-top: 1vmax;
  background: black;
}
.purchase_info .btn:hover {
  color: black;
  background-color: #f3d2c1;
  opacity: 0.9;
}
.purchase_info .quantity button {
  border: 1.5px solid #ddd;
  cursor: pointer;
  font-size: 20px;
  background: #f3d2c1;
  border-radius: 25px;
  text-align: center;
  padding: 5px 20px;
  outline: none;
  margin-right: 0.7vmax;
  margin-bottom: 1vmax;
  transition: all 0.2s ease-in-out;
}
.purchase_info .quantity button:hover {
  background: #fef6e4;
}
.submitDialog {
  display: flex;
  flex-direction: column;
  font-size: 2vmax;
}
.submitDialogTextArea {
  border: 1px solid rgba(0, 0, 0, 0.082);
  margin: 1vmax 0;
  outline: none;
  padding: 1rem;
  font: 300 1rem "Roboto";
}
.social_links {
  display: flex;
  align-items: center;
}
.social_links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  text-decoration: none;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
.social_links a:hover {
  background: #f3d2c1;
}
/* new reviews */
section.review_sec {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  margin-bottom: 4rem;
}
.review_card .user_img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 3px;
  background: #f3d2c1;
}
.review_card .user_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #fff;
}
.review_card {
  position: relative;
  border-radius: 20px;
  /* background: #bae8e8; */
  background: #fff;
  margin: 20px 0;
  width: 280px;
}
.review_card::before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  height: 50%;
  background: #f3d2c1;
  border-radius: 20px 20px 0 0;
}
.card_content {
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 10;
  height: 40vh;
}
.card_content .user_name {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  text-align: center;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.reviewsHeading {
  color: #000000be;
  font: 500 1.4vmax;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.226);
  padding: 1vmax;
  width: 20vmax;
  margin: auto;
  margin-bottom: 2vmax;
}

.noReviews {
  font: 400 1.3vmax;
  text-align: center;
  color: rgba(0, 0, 0, 0.548);
  margin-bottom: 1vmax;
}
.fproduct {
  display: flex;
  align-items: center;
  /* margin-bottom: 8vmax; */
}
.fproduct a {
  text-decoration: none;
}
.PfreqproductConatiner {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.freqproductConatiner {
  /* display: flex; */
  margin: 2vmax 1vmax;
  width: 20vw;
  height: auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}
.freqproductConatiner > img {
  width: 20vw;
  border-radius: 10px;
}

@media (max-width: 1024px) {
  .pcard {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
  }
  .CarouselImage {
    width: 100%;
  }
  .product_detail h2 {
    font-size: 3vmax;
  }
  .product_title {
    font-size: 3vmax;
  }
  .product_price {
    font-size: 1.5vmax;
  }
  .purchase_info input {
    width: 8vmax;
  }
  .purchase_info input,
  .purchase_info .btn {
    padding: 1vmax 1vmax;
    outline: none;
    margin-right: 0.7vmax;
    margin-bottom: 1vmax;
    outline: none;
  }
  .purchase_info .input {
    padding: 0.3vmax 0.3vmax;
  }
  .card_content {
    height: 25vh;
  }
  .reviewsHeading {
    width: 25vmax;
  }

  .review_card .user_img {
    width: 60px;
    height: 60px;
  }
  .review_card::before {
    height: 40%;
  }
  .fproduct {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 2vmax;
  }
  .freqproductConatiner {
    margin: 2vmax auto;
    height: 30vh;
  }
  .freqproductConatiner,
  .freqproductConatiner > img {
    width: 30vw;
  }
  .productImage {
    flex-wrap: wrap;
  }
  .productImage > img {
    width: 18vw;
    height: 10vh;
  }
  .calculation h2 {
    font-size: 2vmax;
  }
  .calculation h3 {
    font-size: 1.5vmax;
  }
  .calculation h4 {
    font-size: 1.3vmax;
  }
}
@media screen and (max-width: 568px) {
  .freqproductConatiner > h4 {
    font-size: 2vmax;
  }
  .card_wrapper {
    margin: 2vmax 1.5vmax;
  }
}

.product-image-display {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-image-container {
  width: full;
  height: full;
  margin-bottom: 20px;
}

.main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 2px solid #ddd;
  border-radius: 10px;
}

.thumbnail-container {
  display: flex;
  gap: 10px;
}

.thumbnail {
  width: 80px;
  height: 80px;
  cursor: pointer;
  object-fit: cover;
  border: 2px solid #ddd;
  border-radius: 5px;
  transition: border 0.3s;
}

.thumbnail.active {
  border: 2px solid #333;
}
