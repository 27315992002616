.productHeading {
  display: flex;
  text-align: center;
  justify-content: center;
  font-size: 3vmax;
  margin-top: 3vmax;
}

.productConatiner {
  display: flex;
  margin: 0vmax auto;
  width: 95vw;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 100%;
}

.viewBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 90vw;
  margin: auto;
  padding-top: 2vmax;
  font-size: 1.3vmax;
}
.iconBtn {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 1vmax;
}
.iconsDisplay {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-bottom: 2vmax;
  background-color: #f3d2c1;
  padding: 3vmax 1vmax;
}
.iconsDisplay .iconContent > h1 {
  padding: 1vmax 0;
  font-size: 2vmax;
}
.iconsDisplay > div {
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.iconsDisplay > div > img {
  width: 15vw;
  margin-bottom: 1vmax;
  border-radius: 50%;
  box-shadow: 0px 3px 3px 3px rgba(0, 0, 0, 0.4);
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3vmax 0px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.aboutHome {
  display: flex;
  margin: 2vmax 0;
}
.artistImage {
  width: 100%;
  height: auto;
  border-radius: 20px;
}
.artistImage img {
  height: 80vh;
  width: 50vw;
  object-fit: cover;
  border-radius: 20px;
}
.artistContent {
  display: flex;
  align-items: center;
  margin-top: 8rem;
  flex-direction: column;
  padding: 30px;
}
.artistContent h2 {
  margin-bottom: 4rem;
  /* font-size: 4rem; */
}

@media (max-width: 1200px) {
  .aboutHome {
    flex-direction: column;
  }
  .artistImage img {
    height: 40vh;
    width: 100%;
  }
  .artistContent {
    margin-top: 2rem;
  }
  .iconsDisplay > div > img {
    height: 10vh;
    width: auto;
  }
  .iconsDisplay > div > h3 {
    font-size: 1.3vmax;
  }
}
@media (max-width: 568px) {
  .iconsDisplay {
    grid-template-columns: 1fr 1fr;
  }

  .iconsDisplay > div {
    padding-bottom: 3vmax;
  }
  .iconsDisplay > div > h3 {
    font-size: 1.3vmax;
  }
  .iconsDisplay > div > h1 {
    font-size: 2vmax;
  }
  .iconsDisplay > div > p,
  a {
    font-size: 1.5vmax;
  }
}
