.sidebar {
  display: flex;
  flex-direction: column;
  padding: 4rem 0;
}
.we {
  font-size: 3rem;
}
.sidebar a {
  text-decoration: none;
  color: black;
  font: 700 1vmax "Quicksand";
  padding: 2rem;
  transition: all 0.5s;
}
.sidebar a > P {
  display: flex;
  align-items: center;
}
.sidebar a > p > svg {
  margin-right: 0.5rem;
}

.MuiTreeItem-label {
  font-weight: bold !important;
  font-size: 1vmax !important;
}
