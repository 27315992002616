.main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 25%;
  min-height: auto;
}
.container {
  position: relative;
  width: 100%;
  padding: 1vmax;
  border-radius: 1vmax;
}
.container .card {
  width: 100%;
  border-radius: 1vmax;
  background: #fffffe;
}
.container .card .imgBox {
  position: relative;
  border-radius: 1vmax 1vmax 5vmax 0;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.container .card .imgBox img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 1vmax 1vmax 5vmax 0;
  transition: all 0.3s ease-in-out;
  transform-origin: right;
}

.action {
  position: absolute;
  top: 10px;
  right: 10px;
}
.action li {
  position: relative;
  cursor: pointer;
  list-style: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fef6e4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  transition: transform 0.5s;
  transform: translateX(60px);
}
.action li:nth-child(2) {
  transition-delay: 0.15s;
}
.action li:nth-child(3) {
  transition-delay: 0.3s;
}
.container .card:hover .action li {
  transform: translateX(0px);
}
.action li:hover {
  background: #f3d2c1;
}
.action li span {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%) translateX(-20px);
  white-space: nowrap;
  padding: 4px 6px;
  background: #fef6e4;
  font-size: 1vmax;
  border-radius: 20px;
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.action li:hover span {
  transform: translateY(-50%) translateX(0px);
  opacity: 1;
}
.action li span::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -4px;
  width: 10px;
  height: 8px;
  background: #fef6e4;
  transform: translateY(-50%) rotate(45deg);
}
.container .card .imgBox img:hover {
  transform: scale(1.1);
}
.container .card .content {
  padding: 0 1vmax;
  text-decoration: none;
}
.container .card .content .productName h3 {
  font-size: 1.1vmax;
  padding: 0 1vmax;
}
.container .card .content .price_rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1vmax;
}
.container .card .content .price_rating h2 {
  font-size: 1.1vmax;
}
.disc {
  text-decoration: line-through;
  color: red;
}
.container .card .content .price_rating .rating {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container .card .content .price_rating .rating span {
  font-size: 1vmax;
}

@media (max-width: 568px) {
  .main {
    flex-basis: 50%;
  }
  .action li {
    width: 20px;
    height: 20px;
  }
  .container .card .content {
    padding: 0 0.1px;
  }
  .discHHp {
    left: 4.8%;
    top: 56%;
  }
}
@media (max-width: 1024px) {
  .action li {
    width: 20px;
    height: 20px;
  }
  .discHHp {
    left: 4.8%;
    top: 60%;
  }

  .container .card .content .productName h3 {
    font-size: 1.3vmax;
    padding-bottom: 0.5vmax;
  }
  .container .card .content .price_rating h2 {
    font-size: 1.3vmax;
  }
  .container .card .content .price_rating .rating span {
    font-size: 1.2vmax;
  }
}
