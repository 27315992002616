.mainH {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: auto;
}
.containerH {
  position: relative;
  width: 100%;
  padding: 1vmax;
  border-radius: 1vmax;
}
.containerH .cardH {
  width: 100%;
  border-radius: 1vmax;
  background: #fffffe;
}
.cardHp {
  height: 40vh;
}
.containerH .cardH .imgBoxH {
  position: relative;
  border-radius: 1vmax 1vmax 5vmax 0;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.containerH .cardH .imgBoxH img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 1vmax 1vmax 5vmax 0;
  transition: all 0.3s ease-in-out;
  transform-origin: right;
}
.imgp {
  height: 33vh;
}
.imgp img {
  width: 100%;
  height: 30vh;
  object-fit: cover;
  border-radius: 1vmax 1vmax 5vmax 0;
  transition: all 0.3s ease-in-out;
  transform-origin: right;
}

.actionH {
  position: absolute;
  top: 10px;
  right: 10px;
}
.actionH li {
  position: relative;
  cursor: pointer;
  list-style: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fef6e4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  transition: transform 0.5s;
  transform: translateX(60px);
}
.actionH li:nth-child(2) {
  transition-delay: 0.15s;
}
.actionH li:nth-child(3) {
  transition-delay: 0.3s;
}
.containerH .cardH:hover .actionH li {
  transform: translateX(0px);
}
.actionH li:hover {
  background: #f3d2c1;
}
.actionH li span {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%) translateX(-20px);
  white-space: nowrap;
  padding: 4px 6px;
  background: #fef6e4;
  font-size: 1vmax;
  border-radius: 20px;
  pointer-events: none;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.actionH li:hover span {
  transform: translateY(-50%) translateX(0px);
  opacity: 1;
}
.actionH li span::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -4px;
  width: 10px;
  height: 8px;
  background: #fef6e4;
  transform: translateY(-50%) rotate(45deg);
}
.containerH .cardH .imgBoxH img:hover {
  transform: scale(1.1);
}
.containerH .cardH .contentH {
  padding: 0 1vmax;
  text-decoration: none;
}
.containerH .cardH .contentH .productNameH h3 {
  font-size: 1.1vmax;
  padding: 0 1vmax;
}
.containerH .cardH .contentH .price_ratingH {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1vmax;
}
.containerH .cardH .contentH .price_ratingH h2 {
  font-size: 1.1vmax;
}
.discH {
  text-decoration: line-through;
  color: red;
}
.discHH {
  text-decoration: line-through;
  color: white;
  position: absolute;
  z-index: 9;
  left: 1%;
  font-weight: bold;
  border-radius: 50%;
  padding: 10px 5px;
  background-color: rgb(165, 5, 5);
}
.discHHH,
.discHHp {
  color: white;
  position: absolute;
  z-index: 19;
  left: 4.3%;
  top: 68%;
  font-weight: bold;
  font-size: 1vmax;
  padding: 0 0.5vmax;
  background-color: black;
}
.discHHp {
  top: 66%;
}
.containerH .cardH .contentH .price_ratingH .ratingH {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.containerH .cardH .contentH .price_ratingH .ratingH span {
  font-size: 1vmax;
}

@media (max-width: 568px) {
  .actionH li {
    width: 10px;
    height: 10px;
  }
  .containerH .cardH .contentH {
    padding: 0 0.1px;
  }
  .discHHH {
    top: 62%;
    left: 4.8%;
  }
  .discHH {
    padding: 10px 5px;
    font-size: 1.5vmax;
  }
  .cardHp {
    height: 25vh;
  }
  .imgp {
    height: 20vh;
  }
}
@media (max-width: 1024px) {
  .actionH li {
    width: 30px;
    height: 30px;
  }
  .containerH .cardH .contentH .productNameH h3 {
    font-size: 1.5vmax;
  }
  .containerH .cardH .contentH .price_ratingH h2 {
    font-size: 1.5vmax;
  }
  .containerH .cardH .contentH .price_ratingH .ratingH span {
    font-size: 1.2vmax;
  }
}
