li {
  list-style: none;
  transition: all 0.5s ease-in-out;
}
a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}
a:hover {
  text-decoration: underline;
}
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  background: #fef6e4;
  padding: 0 10rem;
}
.nav_brand img {
  height: 15vh;
}

.nav_menu,
.nicon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
  padding-top: 10px;
}
.nicon {
  gap: 1rem;
  cursor: pointer;
}
.nicon li:hover {
  background: rgba(248, 245, 245, 0.5);
  border-radius: 50%;
}
.nav_Search {
  background: #fff;
  outline: none;
  border: none;
  width: 15vw;
  height: 6vh;
  max-width: 100%;
  padding: 1rem 0 1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 14.5%;
  right: 10%;
  z-index: 9000;
  border-radius: 15px;
  transition: all 0.3s ease;
}
.nav_Search > input[type="submit"] {
  cursor: pointer;
  border: none;
  background: #f3d2c1;
  padding: 5px 0;
  width: 5vw;
  height: 6vh;
  border-radius: 0 15px 15px 0;
  font-weight: bold;
}
.dn {
  display: none;
  transition: all 0.3s ease;
}
.db {
  display: flex;
  transition: all 0.3s ease;
}
.drawerBtn {
  all: unset;
  font-size: 3rem;
  display: grid;
  position: absolute;
  right: 2%;
}
@media (min-width: 1024px) {
  .drawerBtn {
    display: none;
  }
}
@media (max-width: 900px) {
  .nav_menu {
    display: none;
  }
  .navbar {
    justify-content: center;
  }
}
@media (max-width: 568px) {
  li > a {
    font-size: 2vmax;
  }
}
