.speedDial {
  position: absolute;
  right: 2vmax;
  top: 3vmax;
}
.speedDialIcon {
  width: 56px;
  height: 56px;
  border-radius: 100%;
}

@media screen and (max-width: 900px) {
  .speedDial {
    position: absolute;
    right: 85%;
    top: 7%;
  }
}
