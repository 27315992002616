.emptyCart {
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
.emptyCart > svg {
  font-size: 6vmax;
}
.emptyCart > p {
  font-size: 2vmax;
}
.emptyCart > a {
  font-size: 1vmax;
  background: black;
  color: white;
  border: none;
  padding: 1vmax 3vmax;
  cursor: pointer;
  text-decoration: none;
  border-radius: 10px;
  transition: all 0.3s ease;
  font-size: 1.3vmax;
}
.emptyCart > a:hover {
  background: #f3d2c1;
  color: black;
}
.cartPage {
  padding: 3vmax 5vmax;
  overflow: hidden;
  height: 100vh;
}
.cartPage > h2 {
  text-align: center;
  margin-bottom: 1vmax;
}
.cartHeader {
  border: 1px solid black;
  grid-template-columns: 4fr 1fr 1fr;
  display: grid;
  margin: auto;
  background: #f3d2c1;
  font-weight: 900;
}
.wishHeader {
  border: 1px solid black;
  justify-content: center;
  display: grid;
  margin: auto;
  background: #f3d2c1;
  font-weight: 900;
}
.cartHeader > p,
.wishHeader > p {
  margin: 10px;
}
.cartHeader > p:last-child {
  text-align: end;
}
.overflowcart {
  max-height: 40vh;
  overflow-y: scroll;
}
.overflowcartw {
  max-height: 60vh;
  overflow-y: scroll;
}

.cartContainer,
.wishContainer {
  height: auto;
  margin: 1vmax 0;
  display: grid;
  grid-template-columns: 4fr 1fr 1fr;
  align-items: center;
  border-bottom: 1px solid black;
}
.wishContainer {
  height: 18vh;
  grid-template-columns: 3fr 1fr;
}

.cardInput {
  height: 8vmax;
  display: flex;
  align-items: center;
}
.cardInput > button {
  border: none;
  transition: all 0.3s ease;
  background-color: rgba(0, 0, 0, 0.616);
  color: white;
  cursor: pointer;
  padding: 0.8vmax;
  border-radius: 5px;
}

.cardInput > button:hover {
  background-color: rgba(0, 0, 0, 0.767);
}
.cardInput > input {
  border: none;
  width: 3vmax;
  text-align: center;
  outline: none;
  padding: 0.5vmax;
  color: black;
}

.cardSubTotal {
  display: flex;
  padding: 0.5vmax;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.2vmax;
  font-weight: 900;
}

.cartGrossTotal {
  display: grid;
  justify-content: end;
  grid-template-columns: 1fr;
}
.cartGrossTotalBox {
  border-top: 3px solid black;
  border-bottom: 3px solid black;
  box-sizing: border-box;
  margin: 1vmax 0 1vmax 50vmax;
  display: flex;
  justify-content: space-between;
  padding: 2vmax 0;
  font-weight: 900;
  font-size: 1.3vmax;
}

.checkOutBtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.checkOutBtn > button {
  border: none;
  padding: 0.8vmax 4vmax;
  background: black;
  color: white;
  border-radius: 20px;
  cursor: pointer;
}
.wishBtn {
  border: none;
  padding: 0.8vmax 4vmax;
  /* margin: 1vmax 9vmax; */
  background: black;
  color: white;
  border-radius: 20px;
  cursor: pointer;
}
.checkOutBtn > a button {
  border: none;
  padding: 0.8vmax 4vmax;
  margin: 1vmax 9vmax;
  background: black;
  color: white;
  border-radius: 20px;
  cursor: pointer;
}
@media screen and (max-width: 1024px) {
  .overflowcart {
    max-height: 50vh;
  }
  .wishContainer {
    max-height: 60vh;
  }
  .cartContainer > p {
    font-size: 1.5vmax;
  }
  .cartSubtotal {
    padding: 2vmax;
    font: 300 2vmax "Quicksand";
  }
  .cartGrossTotalBox {
    width: 20vw;
  }
}
@media screen and (max-width: 568px) {
  .cartPage {
    padding: 1vmax;
    min-height: 100vh;
  }
  .overflowcart {
    height: 35vh;
  }
  .wishContainer {
    height: auto;
    grid-template-columns: 4fr 1fr;
  }

  .checkOutBtn {
    flex-direction: column;
  }
  .cartHeader .wishHeader {
    width: 100vw;
    font: 900 1.7vmax "Quicksand";
    grid-template-columns: 3fr 1fr 1fr;
  }
  .wishHeader {
    grid-template-columns: 1fr 1fr;
  }

  .cartContainer {
    width: 100%;
    grid-template-columns: 3fr 1fr 1fr;
  }
  .wishContainer {
    grid-template-columns: 1fr 1fr;
  }
  .cartContainer > p {
    font-size: 2vmax;
  }

  .cartInput {
    height: 24vmax;
    align-items: center;
  }

  .cartInput > button {
    padding: 5vmax;
  }

  .cartInput > input {
    width: 2vmax;
    padding: 1.5vmax;
    font: 400 1.8vmax "Quicksand";
  }

  .cartSubtotal {
    padding: 1.5vmax;
    height: 20vmax;
    font: 300 2vmax "Quicksand";
  }

  .cartGrossTotalBox {
    width: 50vw;
    padding: 2vmax;
    font-size: 2vmax;
    margin-bottom: 3vmax;
  }

  .checkOutBtn > button {
    font: 300 2vmax "Quicksand";
  }

  .wishBtn > button {
    font: 300 2vmax "Quicksand";
  }
  .checkOutBtn > a > button {
    font: 300 2vmax "Quicksand";
  }
}
