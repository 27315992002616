.orderDetailsPage {
  /* background-color: white; */
}

.orderDetailsContainer > h1 {
  font: 300 2vmax "Quicksand";
  margin: 4vmax 0;
  color: tomato;
}

.orderDetailsContainer {
  padding: 3vmax;
  padding-bottom: 0%;
}

.orderDetailsContainer > p {
  font: 400 1.8vmax "Quicksand";
}

.orderDetailsContainerBox,
.orderDetailsCartItemsContainer {
  margin: 2vmax;
}

.orderDetailsContainerBox > div {
  display: flex;
  margin: 1vmax 0;
}

.orderDetailsContainerBox > div > p {
  font: 400 1vmax "Quicksand";
  color: black;
}
.orderDetailsContainerBox > div > span {
  margin: 0 1vmax;
  font: 100 1vmax "Quicksand";
  color: black;
}

.orderDetailsCartItems > p {
  font: 400 1.8vmax "Quicksand";
}

.orderDetailsCartItems {
  padding: 2vmax 3vmax;
  border-top: 1px solid rgba(0, 0, 0, 0.164);
}

.orderDetailsCartItemsContainer > div {
  display: flex;
  font: 400 1vmax "Quicksand";
  align-items: center;
  margin: 2vmax 0;
}

.orderDetailsCartItemsContainer > div > img {
  width: 15vmax;
  border-radius: 15px;
}

.orderDetailsCartItemsContainer > div > a {
  color: black;
  margin: 0 2vmax;
  width: 60%;
  text-decoration: none;
}

.orderDetailsCartItemsContainer > div > span {
  font: 100 1vmax "Quicksand";
  color: black;
}

@media screen and (max-width: 600px) {
  .orderDetailsContainer > p {
    font: 400 6vw "Quicksand";
  }

  .orderDetailsContainerBox > div {
    margin: 6vw 0;
  }

  .orderDetailsContainerBox > div > p {
    font: 400 4vw "Quicksand";
  }
  .orderDetailsContainerBox > div > span {
    font: 100 4vw "Quicksand";
  }

  .orderDetailsCartItems > p {
    font: 400 6vw "Quicksand";
  }

  .orderDetailsCartItemsContainer > div {
    font: 400 4vw "Quicksand";
    margin: 4vw 0;
  }

  .orderDetailsCartItemsContainer > div > img {
    width: 17vw;
  }

  .orderDetailsCartItemsContainer > div > a {
    margin: 2vw;
    width: auto;
  }

  .orderDetailsCartItemsContainer > div > span {
    font: 100 4vw "Quicksand";
  }
}
