.faqDiv {
  width: 95vw;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  text-align: center;
  margin: auto;
  margin-bottom: 1vmax;
}
.faqDiv .item {
  width: 60vw;
  margin: auto;
  padding-bottom: 2vmax;
  padding-top: 2vmax;
  border-bottom: 1px solid rgb(14, 13, 13);
}
.faqDiv .faqHeading {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.faqDiv .faqHeading span {
  padding-right: 1vmax;
  cursor: pointer;
  justify-content: center;
}

.faqContent {
  padding: 0 2vmax;
  text-align: justify;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.6s ease-in-out;
}
.faqContent.show {
  padding: 0 2vmax;
  text-align: justify;
  height: auto;
  opacity: 1;
  max-height: 9999px;
  transition: all 0.6s ease-in-out;
}
.faqPageImg {
  width: 100vw;
  height: 30vh;
  object-fit: cover;
  overflow: hidden;
}

@media screen and (max-width: 600px) {
  .faqPageImg {
    height: 20vh;
    object-fit: fill;
  }
  .faqDiv .item {
    width: 90%;
  }
  .faqContent {
    font-size: 1.5vmax;
  }
  .faqDiv .faqHeading {
    font-size: 1.3vmax;
  }
}
