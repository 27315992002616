.updatePasswordContainer {
  width: 100vw;
  height: 80vh;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0%;
  left: 0;
  border-radius: 20px;
  /* z-index: 6000; */
}

.updatePasswordBox {
  border-radius: 20px;
  /* background-color: white; */
  width: 25vw;
  height: 70vh;
  box-sizing: border-box;
  overflow: hidden;
}
.updatePasswordBox > h2 {
  text-align: center;
  padding-top: 1.5vmax;
  margin: auto;
  width: 70%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.205);
}

.updatePasswordForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 2vmax;
  justify-content: space-evenly;
  height: 80%;
  transition: all 0.5s;
  border: none;
}

.updatePasswordForm > div {
  display: flex;
  width: 100%;
  align-items: center;
}

.updatePasswordForm > div > input {
  padding: 1vmax 4vmax;
  width: 100%;
  border: 0;
  background: transparent;
  border-radius: 10px;
  font: 300 0.9vmax normal;
  outline: none;
  border-bottom: 1px solid black;
}

.updatePasswordForm > div > svg {
  position: absolute;
  transform: translateX(1vmax);
  font-size: 1.6vmax;
  color: rgba(0, 0, 0, 0.623);
}

.updatePasswordBtn {
  border: none;
  background-color: black;
  color: white;
  font: 300 0.9vmax;
  width: 100%;
  padding: 0.8vmax;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
}

.updatePasswordBtn:hover {
  background-color: #f3d2c1;
  color: black;
}

@media screen and (max-width: 1024px) {
  .updatePasswordBox {
    width: 45vw;
  }
}
@media screen and (max-width: 600px) {
  /* .LoginSignUpContainer {
        background-color: white;
      } */
  .updatePasswordBox {
    width: 90vw;
    height: 60vh;
  }

  .updatePasswordForm {
    padding: 5vmax;
  }

  .updatePasswordForm > div > input {
    padding: 5vw 10vw;
    font: 300 4vw normal;
  }
  .updatePasswordForm > div > svg {
    font-size: 2.8vmax;
  }

  .updatePasswordBtn {
    font: 300 1.9vmax "Roboto";
    padding: 1.8vmax;
  }
}
