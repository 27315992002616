.mainPrivacy {
  display: grid;
  grid-template-columns: 3fr 1fr;
}
.mainPrivacy > div > img {
  width: 100%;
}
.privacyPolicy {
  padding: 0 3vmax;
  margin: auto;
}

.privacyPolicy > h2 {
  font-size: 2vmax;
  margin-bottom: 1vmax;
  text-decoration: underline;
}
.privacyPolicy > p {
  margin-bottom: 1vmax;
  text-align: justify;
  /* font-size: 1.3vmax; */
}
.shippingUl {
  padding: 0 3vmax;
}
.shippingUl > li {
  list-style-type: disc;
  /* font-size: 1.3vmax; */
}
.pageNotFound {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .mainPrivacy {
    grid-template-columns: 1fr;
  }
  .pageNotFound > a > img {
    height: 40vh;
  }
}
